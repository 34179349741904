import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { pointsMeter, rewardbg, rewardDefault, golf, flag } from 'images'

const RewardsLoadedView = ({
  pointsMeterWidth,
  imageUrl,
  LeftArrowButtonElement,
  RightArrowButtonElement,
  points,
  maxPoints,
  requiredPoints,
  name,
  RedeemRewardButtonElement,
}) => (
  <div className={styles.rewardContainer}>
    <div className={styles.reward}>
      {imageUrl ? (
        <img className={styles.image} src={imageUrl} alt="Reward" />
      ) : (
        <img
          className={styles.rewardDefault}
          src={rewardDefault}
          alt="Reward"
        />
      )}
    </div>
    {LeftArrowButtonElement}
    {RightArrowButtonElement}
    <div>
      <img className={styles.flag} src={flag} alt="Flag" />
      <img className={styles.golf} src={golf} alt="Golf" />
    </div>
    <div className={styles.meter}>
      <div
        className={styles.progress}
        style={{
          width: `${pointsMeterWidth * 33.4}rem`,
          transition: 'width 2s',
        }}
      >
        <img src={pointsMeter} alt="Meter" />
      </div>
    </div>
    <div className={styles.pointsText}>
      <Text h6 bold dark>
        {points} / {maxPoints}
      </Text>
    </div>
    <Text bold dark className={styles.requiredPoints}>
      {requiredPoints === 0 ? 'FREE' : `${requiredPoints} Points`}
    </Text>
    <Text bold dark center className={styles.name}>
      {name ? name.toUpperCase() : ''}
    </Text>
    <CFView className={styles.redeemButton}>{RedeemRewardButtonElement}</CFView>
  </div>
)

const styles = {
  rewardContainer: css({
    position: 'relative',
    height: '100%',
    width: '100%',
    background: `url(${rewardbg}) no-repeat`,
    backgroundSize: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1rem',
    boxShadow: '2px 2px 5px rgba(0,0,0,.4)',
  }),
  reward: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  image: css({
    zIndex: '1',
    position: 'relative',
    top: '-2.86rem',
    left: '0.12rem',
    width: '16.5rem',
    borderRadius: '10rem',
    boxShadow: '1px 1px 5px rgba(0,0,0,0.7)',
  }),
  rewardDefault: css({
    zIndex: '1',
    position: 'relative',
    left: '.2rem',
    bottom: '2rem',
    objectFit: 'contain',
    width: '17rem',
  }),
  requiredPoints: css({
    position: 'absolute',
    fontFamily: 'antonio',
    fontSize: '2.4rem',
    textTransform: 'uppercase',
    top: '13rem',
  }),
  name: css({
    position: 'absolute',
    fontFamily: 'antonio',
    fontSize: '2.4rem',
    bottom: '16rem',
    textAlign: 'center',
    padding: '0 3rem',
  }),
  redeemButton: css({
    position: 'absolute',
    bottom: '5rem',
  }),
  flag: css({
    zIndex: '1',
    width: '7%',
    position: 'absolute',
    top: '.85rem',
    right: '.7rem',
  }),
  golf: css({
    zIndex: '1',
    width: '7%',
    position: 'absolute',
    top: '2.2rem',
    left: '1.25rem',
  }),
  meter: css({
    position: 'absolute',
    top: '2.38rem',
    left: '2.7rem',
  }),
  progress: css({
    overflow: 'hidden',
  }),
  pointsText: css({
    position: 'absolute',
    top: '5.3rem',
    right: '1.8rem',
  }),
}

export default RewardsLoadedView
