import React, { Component } from 'react'
import { css } from 'emotion'
import { CFView, Space, CFText, Text } from 'components'
import { promotion1, promotion2, promotion3 } from 'images'
import { MEDIA_QUERY } from 'styles'

class Promotions extends Component {
  render() {
    return (
      <div className={styles.container}>
        <CFView row justifyCenter alignCenter mb="3rem">
          <CFText className={styles.header}>OUR SPECIALS</CFText>
        </CFView>
        <CFText className={styles.headerContent}>
          Check out some of our specials! The time has come to try some
          delicious Japanese Food.
        </CFText>
        <div className={styles.row}>
          <div className={styles.promoContainer}>
            <div className={styles.promo}>
              <img className={styles.image} src={promotion1} alt="Promo 1" />
              <Space h2 />
              <Text className={styles.promoHeader} content h3 center>
                RAINBOW ROLL
              </Text>
              <Space h2 />
              <Text className={styles.promoContent}>
                Taste the rainbow! Discover our magical Rainbow Roll!
              </Text>
              <Text className={styles.priceText}>$13.95</Text>
            </div>
          </div>
          <div className={styles.promoContainer}>
            <div className={styles.promo}>
              <img className={styles.image} src={promotion2} alt="Promo 2" />
              <Space h2 />
              <Text className={styles.promoHeader} content h3 center>
                KITSTAYA ROLL
              </Text>
              <Space h2 />
              <Text className={styles.promoContent}>
                The perfect combination of salmon and mango.
              </Text>
              <Text className={styles.priceText}>$13.95</Text>
            </div>
          </div>
          <div className={styles.promoContainer}>
            <div className={styles.promo}>
              <img className={styles.image} src={promotion3} alt="Promo 3" />
              <Space h2 />
              <Text className={styles.promoHeader} content h3 center>
                SALMON ABURI
              </Text>
              <Space h2 />
              <Text className={styles.promoContent}>
                Treat yourself to our delicious flame seared salmon nigiri.
              </Text>
              <Text className={styles.priceText}>$3.00</Text>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    paddingTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '130rem',
    [MEDIA_QUERY.MD]: {
      overflowX: 'auto',
    },
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '110rem',
    justifyContent: 'space-around',
    width: '100%',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      padding: '0 1rem 0 1rem',
      overflowX: 'auto',
    },
  }),
  promoContainer: css({
    width: '30%',
    [MEDIA_QUERY.MD]: {
      minWidth: '90vw',
    },
  }),
  promo: css({
    paddingBottom: '4rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }),
  promoHeader: css({
    fontFamily: 'raleway',
    fontWeight: 'bold',
    color: '#957B60',
  }),
  promoContent: css({
    width: '90%',
    margin: '0 1.5rem',
    fontSize: '1.8rem',
    textAlign: 'center',
    paddingBottom: '2rem',
    color: 'lightGrey',
  }),
  header: css({
    fontFamily: 'raleway',
    fontWeight: '900',
    fontSize: 42,
  }),
  headerContent: css({
    fontSize: 24,
    textAlign: 'center',
    width: '63%',
    maxWidth: '650px',
    paddingBottom: '4rem',
    [MEDIA_QUERY.MD]: {
      fontSize: 22,
      width: '90%',
    },
  }),
  image: css({
    width: '90%',
    [MEDIA_QUERY.MD]: {
      width: '80%',
    },
  }),
  priceText: css({
    fontFamily: 'georgia',
    fontWeight: 'bold',
    color: '#957B60',
    fontSize: '3rem',
  }),
}

export default Promotions
