import React, { Component } from 'react'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

class Card extends Component {
  render() {
    const { children, className, ...props } = this.props
    const classNames = css(styles.container, className)
    return (
      <div className={classNames} {...props}>
        {children}
      </div>
    )
  }
}

const styles = {
  container: css({
    padding: '4rem',
    borderRadius: '1rem',
    backgroundColor: 'rgba(255,255,255,.3)',
    [MEDIA_QUERY.SM]: {
      padding: '2rem',
    },
  }),
}

export default Card
