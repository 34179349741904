import React, { Component } from 'react'
import { css } from 'emotion'
import { CFView, CFText, Space } from 'components'
import { interior } from 'images'
import { MEDIA_QUERY } from 'styles'

export default class Restaurant extends Component {
  render() {
    return (
      <CFView className={styles.container} column>
        <div className={styles.content}>
          <CFView className={styles.contentText} column>
            <CFText className={styles.header}>ABOUT KITSTAYA</CFText>
            <CFText h3 center>
              Located in the beautiful and vibrant Kitsilano area in Vancouver,
              we have been serving our customers a wide range of Japanese dishes
              since 2008.
            </CFText>
            <Space h2 />
            <CFText h3 center>
              Kitstaya Sushi is best known for our “famous rolls”, which are a
              selection of 21 specialty rolls, as well as the “ABURI” flame
              seared & “OSHI” pressed (Osaka Style Sushi) sushi.
            </CFText>
            <Space h2 />
            <CFText h3 center>
              With big portions and an affordable price, Kitstaya Sushi has
              become local favourite enjoyed by many.
            </CFText>
          </CFView>
          <img src={interior} alt="Restaurant" className={styles.restaurant} />
        </div>
      </CFView>
    )
  }
}

const styles = {
  container: css({
    backgroundColor: 'rgba(0,0,0,.5)',
    width: '100%',
    alignItems: 'center',
  }),
  content: css({
    width: '100%',
    maxWidth: '130rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
    },
  }),
  contentText: css({
    padding: '0 4rem 4rem 4rem',
  }),
  restaurant: css({
    width: '55%',
    boxShadow: '1px 1px 5px rgba(0,0,0,.7)',
    [MEDIA_QUERY.MD]: {
      width: '100%',
    },
  }),
  header: css({
    paddingBottom: '4rem',
    textAlign: 'center',
    fontFamily: 'raleway',
    fontWeight: '900',
    fontSize: 42,
    [MEDIA_QUERY.MD]: {
      paddingTop: '4rem',
    },
  }),
}
