import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, Text } from 'components'
import {
  appStore,
  googlePlay,
  locationMap,
  facebook,
  twitter,
  instagram,
} from 'images'
import { MEDIA_QUERY, COLOR } from 'styles'

class Contact extends Component {
  render() {
    const { HoursElement } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <CFLink
            href="https://goo.gl/maps/KEwB4xZq9gAeCM8s9"
            className={styles.locationMap}
          >
            <img
              src={locationMap}
              alt="Kitstaya Sushi Map"
              className={styles.locationMap}
            />
          </CFLink>
          <div className={styles.column}>
            <Text heading h2 bold color={COLOR.red}>
              DOWNLOAD APP
            </Text>
            <Space h2 />
            <CFView w="29.5rem">
              <Text h6 white>
                Kitstaya Sushi is now taking online orders. Redeem points for
                fantastic rewards and discounts. Download our app at:
              </Text>
            </CFView>
            <Space h2 />
            <CFView row>
              <div className={styles.buttonBar}>
                <CFLink href="https://apps.apple.com/us/app/kitstaya-sushi/id1473352227">
                  <img
                    className={styles.mobile}
                    src={appStore}
                    alt="App Store"
                  />
                </CFLink>
                <Space w2 />
                <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.kitstaya">
                  <img
                    className={styles.mobile}
                    src={googlePlay}
                    alt="Goodle Play"
                  />
                </CFLink>
              </div>
            </CFView>
            <Space h3 />
            <Text heading h2 bold color={COLOR.red}>
              SOCIAL MEDIA
            </Text>
            <Space h1 />
            <div className={styles.buttonBar}>
              <CFLink href="https://www.facebook.com/KitstayaSushi/">
                <img className={styles.icon} src={facebook} alt="Facebook" />
              </CFLink>
              <Space w2 />
              <CFLink href="https://twitter.com/KitstayaSushi">
                <img className={styles.icon} src={twitter} alt="Twitter" />
              </CFLink>
              <Space w2 />
              <CFLink href="https://www.instagram.com/kitstayasushi/">
                <img className={styles.icon} src={instagram} alt="Instagram" />
              </CFLink>
              {/* <Space w2 />
              <CFLink href="kitstaya@codefusion.tech">
                <img className={styles.icon} src={email} alt="Email" />
              </CFLink> */}
            </div>
          </div>
          <div className={styles.column}>
            <Text heading h2 bold color={COLOR.red}>
              BUSINESS HOURS
            </Text>
            <Space h2 />
            <div className={styles.hours}>{HoursElement}</div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  }),
  innerContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0,0,0,.5)',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
      textAlign: 'center',
    },
  }),
  locationMap: css({
    width: '45VW',
    objectFit: 'contain',
    paddingRight: '4rem',
    [MEDIA_QUERY.MD]: {
      paddingRight: '0',
      width: '100%',
    },
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
  }),
  mobile: css({
    height: '4rem',
    objectFit: 'contain',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '2rem',
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
    },
  }),
  buttonBar: css({
    display: 'flex',
  }),
  icon: css({
    height: '3.5rem',
    width: '3.5rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
  exterior: css({
    width: '45%',
    [MEDIA_QUERY.MD]: {
      marginTop: '2rem',
      width: '100%',
    },
  }),
}

export default Contact
