import React from 'react'
import { css } from 'emotion'
import PageFooter from './PageFooter'
import Hero from './Hero'
import Promotions from './Promotions'
import Restaurant from './Restaurant'
import About from './About'
import Contact from './Contact'
import { homebg } from 'images'

class HomeView extends React.Component {
  render() {
    const {
      HoursElement,
      ContactUsElement,
      OrderNowButtonElement,
      LogoElement,
    } = this.props
    return (
      <section className={styles.container}>
        <Hero OrderNowButtonElement={OrderNowButtonElement} />
        <div className={styles.bgContainer}>
          <Promotions />
          <Restaurant />
          <About />
        </div>
        <Contact
          HoursElement={HoursElement}
          LogoElement={LogoElement}
          ContactUsElement={ContactUsElement}
        />
        <PageFooter />
      </section>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  bgContainer: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${homebg}) repeat-y`,
    backgroundSize: 'stretch',
  }),
}

export default HomeView
