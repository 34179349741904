export * from 'cf-core/src/images'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cflogo.png'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/hero.png'
export const promotion1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563489155/kitstaya/promotion1.png'
export const promotion2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563489155/kitstaya/promotion2.png'
export const promotion3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563489155/kitstaya/promotion3.png'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/foodGallery.png'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/homebg.jpg'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/locationMap.jpg'
export const promotionsHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/promotionsHeader.png'
export const aboutHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/aboutHeader.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/logo.png'
export const orderButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1593666366/kitstaya/orderButton.png'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1593666366/kitstaya/downloadButton.png'
export const containerbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/containerbg.jpg'
export const interior =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/interior.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/restaurant.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/contactbg.jpg'
export const galleryHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/galleryHeader.png'
export const kitstayaLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/kitstayaLogo.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563489155/kitstaya/rewardbg.jpg'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/rewardArrowRight.png'
export const flag =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/flag.png'
export const golf =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/golf.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/facebook.jpg'
export const twitter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/twitter.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/instagram.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:good/v1563488645/kitstaya/rewardDefault.png'
