import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, CFText, Space, PageHeader } from 'components'
import { hero, downloadButton } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <PageHeader />
        <div className={styles.hero}>
          <img src={hero} alt="Sushi" className={styles.sushi} />
          <CFView className={styles.content}>
            <CFText className={styles.header}>Now Taking Online Orders</CFText>
            <Space height="2rem" />
            <CFText className={styles.heroContentText} h2>
              <CFText red bold>
                Free Edamame
              </CFText>{' '}
              on your first online order! Find it in the rewards section in the
              cart page!
            </CFText>
            <Space height="3rem" />
            <CFView>
              {OrderNowButtonElement}
              <Space height=".7rem" />
              <div>
                <CFLink href="http://onelink.to/g3pfnu">
                  <img
                    src={downloadButton}
                    alt="Download App"
                    className={styles.downloadButton}
                  />
                </CFLink>
              </div>
            </CFView>
          </CFView>
        </div>
      </div>
    )
  }
}
const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    maxWidth: '135rem',
    display: 'flex',
    flexDirection: 'column',
    [MEDIA_QUERY.MD]: {
      height: 'auto',
      paddingBottom: '2rem',
    },
  }),
  hero: css({
    height: 'calc(100vh - 10rem)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.MD]: {
      height: 'calc(100vh - 13rem)',
      flexDirection: 'column',
    },
  }),
  sushi: css({
    width: '60%',
    [MEDIA_QUERY.MD]: {
      padding: '0',
      width: '100%',
    },
  }),
  downloadButton: css({
    width: '250px',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '3rem',
    [MEDIA_QUERY.MD]: {
      paddingLeft: 0,
      alignItems: 'center',
      textAlign: 'center',
    },
  }),
  header: css({
    fontFamily: 'raleway',
    fontWeight: '900',
    textTransform: 'uppercase',
    fontSize: 52,
    [MEDIA_QUERY.MD]: {
      padding: '0 2rem',
      fontSize: 36,
    },
  }),
  heroContentText: css({
    fontFamily: 'roboto',
    fontSize: 22,
    maxWidth: '90VW',
    [MEDIA_QUERY.MD]: {
      padding: '0 2rem',
      fontSize: 20,
    },
  }),
}

export default Hero
