import React from 'react'
import { orderButton } from 'images'
import { css } from 'emotion'

export default () => (
  <img src={orderButton} alt="Order Now" className={styles.orderButton} />
)

const styles = {
  orderButton: css({
    width: '250px',
  }),
}
