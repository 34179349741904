import React, { Component } from 'react'
import { css } from 'emotion'
import { CFView, CFText } from 'components'
import { foodGallery } from 'images'
import { MEDIA_QUERY } from 'styles'

class About extends Component {
  render() {
    return (
      <div className={styles.container}>
        <CFView row justifyCenter alignCenter mv="4rem">
          <CFText className={styles.header}>OUR GALLERY</CFText>
        </CFView>
        <img className={styles.gallery} src={foodGallery} alt="Food Gallery" />
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    marginBottom: '8rem',
    maxWidth: '118rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [MEDIA_QUERY.MD]: {
      marginBottom: '4rem',
    },
  }),
  header: css({
    fontFamily: 'raleway',
    fontWeight: '900',
    fontSize: 42,
  }),
  gallery: css({
    width: '100%',
    objectFit: 'cover',
    [MEDIA_QUERY.MD]: {
      objectFit: 'contain',
    },
  }),
}

export default About
